import React, { useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import GoogleMapReact from "google-map-react"
import ContactForm from "../../components/forms/contact"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import Obfuscate from "react-obfuscate"

const Contact = () => {
    const [center] = useState({
        lat: 51.92334,
        lng: 4.47415
    })
    const [zoom] = useState(6)

    const mapOptions = {
        disableDefaultUI: true
    }

    const markers = [
        { lat: 51.92334, lng: 4.47415 } // Rotterdam
    ]

    const renderMarkers = (map, maps) => {
        const mapMarkers = markers.forEach(
            marker =>
                new maps.Marker({
                    position: marker,
                    map
                })
        )
        return mapMarkers
    }

    return (
        <Layout>
            <Seo title="Contact" />
            <div className="contact-map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDHbjASsFo0OsTChaxi9ymgqBibepTAn7E" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={mapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                ></GoogleMapReact>
            </div>

            <div className="container-md pb-5">
                <div className="row contact-card">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title">Contact us</h1>
                                <p className="card-text">
                                    Give us a call or use the below form for general enquries, and we will get back to you as soon as
                                    possible. Contact details per location are mentioned below.
                                </p>
                                <div className="row mb-3">
                                    <div className="col-12 ">
                                        <ContactForm environment={5000} />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>Rotterdam</h3>
                                        <StaticImage
                                            width={693}
                                            aspectRatio={2}
                                            className="mb-3 rounded"
                                            src="../../images/company/rotterdam.jpg"
                                            alt="weena 290"
                                        />
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                <svg width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>
                                                </div>
                                                <div className="flex-grow-1">Parklaan 26</div>
                                            </div>
                                            <div className="d-flex">
                                                <div>
                                                <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-3 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                </div>
                                                <div className="flex-grow-1">3016 BC Rotterdam</div>
                                            </div>
                                            <div className="d-flex">
                                                <div>
                                                <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-3 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                </div>
                                                <div className="flex-grow-1"> The Netherlands</div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="arabunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>
                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="phone" class="svg-inline--fa fa-phone fa-w-16 fa-fw  me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476.5 22.9L382.3 1.2c-21.6-5-43.6 6.2-52.3 26.6l-43.5 101.5c-8 18.6-2.6 40.6 13.1 53.4l40 32.7C311 267.8 267.8 311 215.4 339.5l-32.7-40c-12.8-15.7-34.8-21.1-53.4-13.1L27.7 329.9c-20.4 8.7-31.5 30.7-26.6 52.3l21.7 94.2c4.8 20.9 23.2 35.5 44.6 35.5C312.3 512 512 313.7 512 67.5c0-21.4-14.6-39.8-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3C463 286.9 286.9 463 69.3 464z"></path></svg> 
                                                </div>
                                                <div className="flex-grow-1">+ 31 10 318 24 00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>UK</h3>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="ukbunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>Hamburg</h3>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="hamburgbunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>Geneva</h3>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                    <FontAwesomeIcon className="me-3" fixedWidth icon={["far", "envelope"]} />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="genevabunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <div className="d-flex align-items-center">
                                            <h3>St. Petersburg</h3>
                                            <span className="ms-3 mb-2">(Rep. Office)</span>
                                        </div>

                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                    <FontAwesomeIcon className="me-3" fixedWidth icon={["far", "envelope"]} />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="balticbunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>Singapore</h3>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                    <FontAwesomeIcon className="me-3" fixedWidth icon={["far", "envelope"]} />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="singbunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="mt-3 mt-md-0 col-md-6 mb-2 mb-md-4">
                                        <h3>Dubai</h3>
                                        <div className="mb-2">
                                            <div className="d-flex">
                                                <div>
                                                    <FontAwesomeIcon className="me-3" fixedWidth icon={["far", "envelope"]} />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <Obfuscate className="text-break" email="dubaibunkers@wearedelta.com" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contact
